const colors = {
  selectedDashboard: '#51459E', // angewähltes Dashboard
  selectedDashboardTransparent: '#51459E82', // angewähltes Dashboard Edit Buttons Hintergrund
  invisibleDashboardColor: '#6c768725',
  backgroundColor: '#FAFAFA',
  menuBarBackground: '#FFFFFF',
  panelBackground: '#FEF3F0',
  drawerBackground: '#FFFFFF',
  drawerBackgroundTransparent: '#6c768700',

  // CHARTS
  chartGrid: '#51459E',
  chartBar: '#F9896B',
  chartFont: '#51459E',

  // LISTVIEW
  poiBackground: '#1B1B1B',
  poiImageCopyrightBackground: 'rgba(0, 0, 0, 0.5)',

  // SCROLLBAR
  scrollbarMain: '#2f3a4e82',
  scrollbarBg: '#323232',

  // WIDGET
  widgetTop: '#FEF3F0',
  widgetContent: '#FEF3F0',
  widgetBackground: '#FEF3F0', // only visibile bottom part when editing, inside preview

  // BUTTONS
  activeButtonBackground: '#323232',

  // TEXT ELEMENTS
  subheadline: '#51459E',
  text: '#51459E',
  textDark: '#51459E',
  inputFieldOutline: '#51459E',

  // COLORS
  colorDetail: '#F9896B', // detail color
  iconColor: '#F9896B',
  white: '#51459E', // Schrift Dashboard + dashboards (in drawer)
  grey: '#666666',
  grayed: '#D5D7D9', // Icons -> Header Leiste: Dashboard, widget, panel bearbeiten/ Hinzufügen / Löschen
  navy: '#003552',
  petrol: '#006871',
  pink: '#C066A1',
  orange: '#CF953F',
  attributeColors: [
    '#F9896B',
    '#CF953F',
    '#DBE027',
    '#9EA226',
    '#003552',
    '#006871',
    '#C066A1',
    '#04beaf',
    '#0587bb',
    '#f6d702',
    '#ff9f00',
  ], // Chart farben
}

export default colors
